import Module from "./Module";

export default class ModuleStickyDrawer extends Module {
    constructor(el) {
        super(el);
    }

    init() {
        this.toggleContent();
    }

    toggleContent() {
        let deviceAction = 'touchstart' in document ? 'touchstart' : 'click';
        this.stickyDrawer = document.querySelector('.stickyDrawer');
        this.button = document.querySelector('.stickyDrawer__cta');
        this.dialog = document.querySelector('.stickyDrawer__content');
        this.overlay = document.querySelector('.stickyDrawer__overlay');
        this.icon = document.querySelector('.handlebar__icon svg');
        this.title = document.querySelector('.handlebar__info');

        this.setAccessibility();

        this.button.addEventListener(deviceAction, () => {
        
            $('.stickyDrawer .slick-slider').each(function () {
                setTimeout(() => {
                    $(this).slick("getSlick").refresh();
                },0);
            });

            if (this.dialog.getAttribute('data-state') == "closed") {
                this.openDialog();
            } else {
                this.closeDialog();
            }
        });

        this.overlay.addEventListener('click', () => {
            if(!this.overlay.classList.contains('disabled-overlay')){
                this.closeDialog();
            }
            this.overlay.classList.add('disabled-overlay');
        });
    }

    setAccessibility() {
        var focusableEls = this.dom.el.querySelectorAll('a[href], area[href], button, input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
        this.focusableEls = Array.prototype.slice.call(focusableEls);
        this.firstFocusableEl = this.focusableEls[0];
        var varFirstFocusableEl = this.focusableEls[0];
        this.focusableLinks = this.dom.el.querySelectorAll('a');
        this.lastFocusableLink = this.focusableLinks[this.focusableLinks.length - 1];
        this.lastFocusableEl = this.focusableEls[this.focusableEls.length - 1];

        var allFocusableEls = document.querySelectorAll('a[href], area[href], button, input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]');
        this.allFocusableEls = Array.prototype.slice.call(allFocusableEls);
        this.firstFocusableElinDoc = this.allFocusableEls[0];

        document.addEventListener('keyup', (e) => {
            if (this.dialog.getAttribute('data-state') == "open") {
                this.handleKeyPress(e);
            }
        });

        this.dialog.addEventListener('keydown', (e) => {
            this.handleKeyDown(e);
        });

        this.lastFocusableEl.addEventListener('keydown', (e) => {
            if (e.which === 13) {
                e.preventDefault();
                this.lastFocusableEl.click();
                varFirstFocusableEl.focus();
            }
        });
    }

    openDialog() {
        $('.stickyDrawer__content').slideToggle(400, () => {
            document.body.scroll = "no";
            this.dom.html.style.overflow = 'hidden';
            this.dom.body.style.overflow = 'hidden';
            this.dialog.setAttribute('data-state', 'open');
            this.dialog.setAttribute('aria-hidden', 'false');
            this.overlay.style.opacity = '1';
            this.overlay.style.visibility = 'visible';
            this.overlay.classList.remove('disabled-overlay');
            this.icon.style.transform = 'rotate(90deg)';
            this.title.innerHTML = 'Close promotions modal';
            this.stickyDrawer.style.zIndex = "101";
            this.stickyDrawer.style.maxHeight = "none";
            this.firstFocusableEl.focus();
        });
    }

    closeDialog() {
        $('.stickyDrawer__content').slideToggle(400, () => {
            document.body.scroll = "yes";
            this.dom.html.removeAttribute('style');
            this.dom.body.removeAttribute('style');
            this.dom.html.style.overflowX = "hidden";
            this.dom.body.style.overflowX = "hidden";
            this.dialog.setAttribute('data-state', 'closed');
            this.dialog.setAttribute('aria-hidden', 'true');
            this.overlay.style.opacity = '0';
            this.overlay.style.visibility = 'hidden';
            this.icon.style.transform = 'rotate(-90deg)';
            this.title.innerHTML = 'Open promotions modal';
            this.stickyDrawer.style.zIndex = "19";
            this.stickyDrawer.style.maxHeight = "272px";
            // First element of the menu 
            const firstElemNav = document.querySelectorAll('.focus-first-element');
            if (firstElemNav.length !== 0) {
                // In the universalNav there are 2 focus-first-element for desktop and mobile
                if (firstElemNav.length > 1) {
                    if (window.matchMedia("(max-width: 1280px)").matches) {
                        firstElemNav[0].focus();
                    } else {
                        firstElemNav[1].focus();
                    }
                } else {
                    firstElemNav[0].focus();
                }
            } else {
                this.firstFocusableElinDoc.focus();
            }
        });
    }

    handleKeyPress(e) {
        var KEY_ESC = 27;

        if (e.keyCode === KEY_ESC) {
            this.closeDialog();
        }
    }

    handleKeyDown(e) {

        var KEY_TAB = 9;

        if (!e.shiftKey && e.keyCode === KEY_TAB) {
            if (document.activeElement === this.lastFocusableEl || document.activeElement === this.lastFocusableLink) {
                e.preventDefault();
                this.firstFocusableEl.focus();
            }
        }

        if (e.shiftKey && e.keyCode === KEY_TAB) {
            if (document.activeElement === this.firstFocusableEl) {
                e.preventDefault();
                this.lastFocusableLink.focus();
            }
        }
    }

    setCookie(sName, sValue) {
        var today = new Date(),
            expires = new Date();
        expires.setTime(today.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = sName + "=" + encodeURIComponent(sValue) + ";expires=" + expires.toGMTString();
    }

    getCookie(sName) {
        var oRegex = new RegExp("(?:; )?" + sName + "=([^;]*);?");

        if (oRegex.test(document.cookie)) {
            return decodeURIComponent(RegExp["$1"]);
        } else {
            return null;
        }
    }

}