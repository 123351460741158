import Module from "./Module";

export default class ModuleTootltip extends Module {
    constructor(el) {
        super(el);
    }

    init() {
        this.setTooltipAction();
    }

    setTooltipAction() {
        let tooltip = this.dom.el,
            tooltipBox = this.dom.el.querySelector(".tooltip__box");

        tooltip.addEventListener("click", () => {
            if (this.dom.el.classList.contains('tooltip__opened')) {
                this.tooltipAction("hide");
            } else {
                this.tooltipAction("show");
            }
        });

        tooltip.addEventListener("mouseover", () => {
            this.tooltipAction("show");
        });

        tooltip.querySelector("button").addEventListener("focus", () => {
            this.tooltipAction("show");
        });

        tooltip.addEventListener("mouseout", () => {
            this.tooltipAction("hide");
        });

        tooltip.querySelector("button").addEventListener("blur", () => {
            this.tooltipAction("hide");
        });
    }

    tooltipAction(state) {
        let tooltip = this.dom.el,
            tooltipBox = this.dom.el.querySelector(".tooltip__box");

        if (state === "show") {
            tooltip.classList.add('tooltip__opened');
            tooltipBox.style.display = "block";
            tooltipBox.setAttribute("aria-hidden", false);
        } else {
            tooltip.classList.remove('tooltip__opened');
            tooltipBox.style.display = "none";
            tooltipBox.setAttribute("aria-hidden", true);
        }
    }
}
