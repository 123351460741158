import Module from './Module';

export default class ModuleDealsCarousel extends Module {
    
    constructor(el) {
        super(el);
        // $.extend(this.dom, {
        //     carousel:      this.dom.el.find('.carousel__items'),
        //     carouselItems: this.dom.el[0].querySelectorAll('.carousel__item'),
        //     buttonWrapper: this.dom.el[0].querySelector('.carousel__btn-wrapper')
        // });
        
        this.dom.$el = $(this.dom.el);
        this.dom.carousel = this.dom.$el.find(".carousel__items");
        this.dom.carouselItems = this.dom.el.querySelectorAll('.carousel__item');
        this.dom.buttonWrapper = this.dom.el.querySelector('.carousel__btn-wrapper');
        this.dom.breakpoint = {
            xs:  479,
            xsl: 668,
            sm:  768,
            md:  992,
            lg:  1280,
            xl:  1429
        };
		
		this.settings = {
            accessibility: false,
            infinite: false,
			arrows: false,
            dots: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            cssEase: 'linear',
            mobileFirst: true,
            // focusOnSelect: true,
            responsive: [
                {
					breakpoint: this.dom.breakpoint.xs - 1,
					settings: {
                        slidesToScroll: 1,
                        slidesToShow: 2,
                        arrows: false,
						appendArrows: this.dom.buttonWrapper
                	}
                },
                {
					breakpoint: this.dom.breakpoint.md - 1,
					settings: {
                        slidesToScroll: 3,
                        slidesToShow: 3,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                },
                {
					breakpoint: this.dom.breakpoint.lg - 1,
					settings: {
                        slidesToScroll: 3,
                        slidesToShow: 3,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                },
                {
					breakpoint: 1600,
					settings: {
                        slidesToScroll: 4,
                        slidesToShow: 4,
                        arrows: true,
						appendArrows: this.dom.buttonWrapper
                	}
                }
            ]
		}
    }

    init() {
        this.dom.carousel.slick(this.settings);
    
        if(this.dom.el.querySelector('.slick-next')) {
            if(this.dom.w.innerWidth >= this.dom.breakpoint.sm) {
                this.dom.el.querySelector('.slick-prev').innerHTML = this.dom.buttonWrapper.dataset.buttonPrev;
                this.dom.el.querySelector('.slick-next').innerHTML = this.dom.buttonWrapper.dataset.buttonNext;
            }
        }

        let dotsContainer = this.dom.el.querySelector('.slick-dots');
		
		if(dotsContainer){
			dotsContainer.setAttribute('role','presentation');

			let dots = this.dom.el.querySelectorAll('.slick-dots button');
	
			[].forEach.call(dots, function(element) {
				element.setAttribute('tabindex','-1');
			});
        }      
    }

}
