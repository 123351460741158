import Module from "./Module";
import Mustache from "../../../../../node_modules/mustache";
import "../../../../../node_modules/slick-carousel";

// Services
import GetData from "../../../../boot/assets/js/services/get-data";
import ManageFocus from "../../../../boot/assets/js/services/manage-focus";


export default class ModuleCaroussel extends Module {
    constructor(el) {
        super(el);

        this.initialSettings = {
            mobileFirst: true,
            speed: 400,
            touchMove : "true",
            cssEase: "ease-out",
            swipe: "true",
            prevArrow: this.dom.el.querySelector(".caroussel__btn.-prev"),
            nextArrow: this.dom.el.querySelector(".caroussel__btn.-next"),
            dotsClass: "caroussel__dots",
            appendDots: this.dom.el.querySelector(".caroussel__dots"),
            accessibility: false
        };

        this.dom.$el = $(this.dom.el);
        this.$caroussel = this.dom.$el.find(".caroussel__content");
        this.$slide = this.dom.$el.find(".slick-slide.slick-active");
        this.$tooltip = this.dom.$el.find(".tooltip");
        this.initialSlideCenter = this.dom.el.dataset.initialslidecenter;
        this.sliderWidth = this.dom.el.dataset.sliderWidth;
        this.carousselLength = this.dom.el.querySelectorAll(
            ".caroussel__item"
        ).length;
    }

    init() {
        if (this.sliderWidth == null) {
            this.dom.body.style.overflowX = "hidden";
            this.dom.html.style.overflowX = "hidden";
        }

        this.injectNewModel();
    }

    injectNewModel() {
        // return GetData().done(data => {
        //     let template = this.dom.el.querySelector(".template").innerHTML;
        //     let rendered = Mustache.render(template, data);
        //     Mustache.parse(template);

        //     this.dom.el.setAttribute(
        //         "data-settings",
        //         rendered.trim().replace(/'/g, '"')
        //     );
            this.newSettings = JSON.parse(this.dom.el.dataset.settings);

            this.setSlideCenter();
            this.updateSettings();
            this.initialCaroussel();
            this.setAccessibility();
            this.onClickInactiveSlides();
            ManageFocus();

            if (this.$tooltip.length > 0) {
                this.checkTooltip();
                this.$caroussel.on(
                    "afterChange",
                    (event, slick, currentSlide) => {
                        this.checkTooltip();
                    }   
                );
            }

            if (this.dom.html.classList.contains('Safari')) {
                this.dom.el.querySelector(".show-caroussel .caroussel__btn.-prev").style.top = '25%';
                this.dom.el.querySelector(".show-caroussel .caroussel__btn.-next").style.top = '25%';
            }

        // });
    }

    setSlideCenter() {
        if (
            this.initialSlideCenter == "true" &&
            this.newSettings.initialSlide
        ) {
            const midPosition = (this.carousselLength / 2).toFixed() - 1; // - 1 convert string to number and because dot begin at position 0

            this.newSettings = Object.assign({}, this.newSettings, {
                initialSlide: midPosition
            });
        }
    }

    updateSettings() {
        this.updatedSettings = Object.assign(
            {},
            this.initialSettings,
            this.newSettings
        );
    }

    initialCaroussel() {
        this.$caroussel.slick(this.updatedSettings);
    }

    // HACK TO OVERWRITE THE ACCESSIBILITY OF SLICK BECAUSE ITS BUGGY NEED TO UPGRADE THE DEP WHEN IT WILL BE FIXED
    setAccessibility() {
        this.setAriaHidden(".slick-current, .slick-active", false);
        this.setRoleToDots();
        this.setTabIndex();

        this.$caroussel.on("afterChange", (event, slick, currentSlide) => {
            this.setAriaHidden(".slick-slide", true);
            this.setAriaHidden(".slick-current, .slick-active", false);
            this.setRoleToDots();
            this.setTabIndex();
        });
    }

    setRoleType(node, val) {
        this.$caroussel.find(node).attr("role", val);
    }

    setAriaHidden(node, val) {
        this.$caroussel.find(node).attr("aria-hidden", val);
    }

    setRoleToDots() {
        let carousselDots = this.$caroussel[0].slick.$dots;
        if (carousselDots) {
            carousselDots.find("li").attr("role", "presentation");
            carousselDots.find("button").attr("tabindex", "-1");
            carousselDots.find("button").attr("aria-hidden", "true");
            carousselDots.find("button").removeAttr("type");
        }
    }

    setTabIndex() {
        let activeSlide = this.$caroussel.find('.slick-slide.slick-active');
        let inactiveSlides = this.$caroussel.find('.slick-slide').not('.slick-active');
        activeSlide.find("button").attr("tabindex", "0");
        activeSlide.find("a").attr("tabindex", "0");
        inactiveSlides.find("button").attr("tabindex", "-1");
        inactiveSlides.find("a").attr("tabindex", "-1");
    }

    checkTooltip() {
        let slides = this.$caroussel.find('.slick-slide.slick-active'),
            slidesToArray = Array.apply(null, slides),
            prevBtn = this.dom.el.querySelector('.caroussel__btn.-prev'),
            nextBtn = this.dom.el.querySelector('.caroussel__btn.-next');

        prevBtn.style.zIndex = "10";
        nextBtn.style.zIndex = "10";

        slidesToArray.forEach((slide, i) => {
            if (slide.querySelector(".tooltip")) {
                slide.querySelector('.tooltip').addEventListener("click", () => {
                    if (slide.querySelector('.tooltip').classList.contains('tooltip__opened')) {
                        this.setTooltipZindex(true, slide);
                    } else {
                        this.setTooltipZindex(false, slide);
                    }
                });

                slide.querySelector('.tooltip').addEventListener("mouseover", () => {
                    this.setTooltipZindex(true, slide);
                });

                slide.querySelector('.tooltip').addEventListener("mouseout", () => {
                    this.setTooltipZindex(false, slide);
                });

                slide.querySelector('.tooltip__icon').addEventListener("focus", () => {
                    this.setTooltipZindex(true, slide);
                });

                slide.querySelector('.tooltip__icon').addEventListener("blur", () => {
                    this.setTooltipZindex(false, slide);
                });
            }
        });
    }

    setTooltipZindex(state, slide) {
        let currentSlide = slide,
            activeSlides = this.$caroussel.find('.slick-slide.slick-active'),
            slidesToArray = Array.apply(null, activeSlides),
            prevBtn = this.dom.el.querySelector('.caroussel__btn.-prev'),
            nextBtn = this.dom.el.querySelector('.caroussel__btn.-next');

        if (state === true) {
            if (currentSlide === slidesToArray[0]) {
                prevBtn.style.zIndex = "0";
            } else if (currentSlide === slidesToArray[slidesToArray.length - 1]) {
                nextBtn.style.zIndex = "0";
            }
        } else {
            if (currentSlide === slidesToArray[0]) {
                prevBtn.style.zIndex = "10";
            } else if (currentSlide === slidesToArray[slidesToArray.length - 1]) {
                nextBtn.style.zIndex = "10";
            }
        }
    }

    onClickInactiveSlides() {
        this.$caroussel.on('click', '.slick-slide', function (e) {
            e.stopPropagation();
            var index = $(this).data("slick-index");
            if ($('.slick-slider').slick('slickCurrentSlide') !== index) {
              $('.slick-slider').slick('slickGoTo', index);
            }
        });
    }
}
